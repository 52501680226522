
$(window).load(function(){
    var carouselHeight = $('.carousel-1').height();
    var joinUsBlock = $('.join-us');
    var totalHeight = carouselHeight + 30;

    var height = totalHeight/2.7;

    $(window).scroll(function() {
        var position = $(window).scrollTop();
        var bottomPosition = (position + height) - joinUsBlock.offset().top;
        
        if(position > height && bottomPosition < -400) {
            $('.links-menu').css("opacity", "1");
            $('.links-menu').css("visibility", "visible");
        }
        else {
            $('.links-menu').css("opacity", "0");
            $('.links-menu').css("visibility", "hidden");
        }
    });
});
