// Search
var search = document.getElementById("search");

function searchState(state) {
    if (state == 'mediaDesktop') {
        $('.search-box__wrapper').dropDownMenu({
            disabled:    false,
            classBtn:    'button-1 button-1--no-icon button-1--svg',
            independent: false
        });
        if (search) {
            search.style.display = "block";
        }
    } else if ((state == 'mediaSmartphone') || (state == 'mediaTablet')) {
        $('.search-box__wrapper').dropDownMenu({
            disabled: true,
            classBtn: 'button-1 button-1--no-icon button-1--svg',
            independent: false
        });
        if (search) {
            search.style.display = "block";
        }
    }
}

// clone search box
var search1Clone = "search-clone";

var cloneSearch = function () {

    if (search) {
        var searchCloned = search.cloneNode(true);
        searchCloned.id = search1Clone;

        var parentNode = document.getElementsByClassName("menu-main-1__sublevel");
        insertAfter(parentNode[0], searchCloned);
        search.style.display = "none";

        var searchClone = document.getElementById("search-clone");
        searchClone.style.display = "block";
    }
};
