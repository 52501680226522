// carouselCollection
function carouselServiceState() {
    var carouselWrap = $('.service-gallery');
    var carouselClone = $('.service-gallery-clone');
    var carousel = $('.service-gallery__listitems', carouselClone);
    var carouselItems = $('.service-gallery__article', carouselWrap).length;
    var tablet = window.matchMedia("(max-width: 1279px)");
    var smartphone = window.matchMedia("(max-width: 767px)");

    if (carouselWrap.length > 0) {

        function cloneCarousel(effect) {
            carouselWrap.clone().appendTo('.services-bloc').addClass('service-gallery-clone');

            carouselClone = $('.service-gallery-clone'),
                carousel = $('.service-gallery__listitems', carouselClone);
                carouselWrapper = $('.service-gallery__wrapper', carouselClone);

            if ($('.service-gallery__prev-next', carouselClone).length === 0) {

                var createPrevNext = function () {
                    carousel.parent().append('<ul class="service-gallery__prev-next">' +
                        '<li class="service-gallery__prev"><button type="button"><img src="' + pathImg + 'carousel-service/prev.png" alt="' + l10n['slide'] + ' ' + l10n['prev'] + '"></button></li>' +
                        '<li class="service-gallery__next"><button type="button"><img src="' + pathImg + 'carousel-service/next.png" alt="' + l10n['slide'] + ' ' + l10n['next'] + '"></button></li>' +
                        '</ul>');
                }

                if (effect === 'scrollHorz' && carouselItems > 8) {
                  //  createPrevNext();
                } else if (effect === 'carousel' && carouselItems > 1) {
                    createPrevNext();
                }

            }
        }

        function removeCarousel() {
            carouselClone = $('.service-gallery-clone');

            if (carouselClone.length > 0) {
                carouselClone.remove();
            }
        }

        function runCarousel(itemStyle, effect, itemsVisible) {
            removeCarousel();
            cloneCarousel(effect);

            carouselClone = $('.service-gallery-clone'),
                carousel = $('.service-gallery__listitems', carouselClone);

            carouselWrap.css("display", "none");
            $('.service-gallery.service-gallery-clone').css("display", "block");

            var carouselCycle = function(itemStyle, effect, itemsVisible) {
                var autoHeight = 'calc';
                if (itemsVisible === 1) {
                    autoHeight = 'calc';
                }
                carousel.cycle({
                    log:        false,
                    fx:         effect,
                    carouselVisible: itemsVisible,
                    carouselFluid:   true,
                    speed:      '600',
                    timeout:    0,
                    swipe:      true,
                    prev:       '.service-gallery__prev',
                    next:       '.service-gallery__next',
                    slides:     '.' + itemStyle,
                    autoHeight: autoHeight,
                   // pager:      '.service-gallery__pager',
                    allowWrap:  false
                });

                function carouselWrapperCalc() {
                    var carouselWrapperHeight = 230;
                    carouselWrapper.each(function(index, item){
                        var carouselWrapperHeightNew = item.offsetHeight;
                        if (carouselWrapperHeightNew > carouselWrapperHeight) {
                            carouselWrapperHeight = carouselWrapperHeightNew;
                        }
                    })
                    return carouselWrapperHeight;
                }
                carouselWrapper.each(function(){
                    $(this).css('min-height', carouselWrapperCalc());
                })

            }
            carouselCycle(itemStyle, effect, itemsVisible);
        }

        function initCycle() {

            if (tablet.matches && smartphone.matches) {
                runCarousel("service-gallery__article", 'carousel', 1);
            } else if (!smartphone.matches && tablet.matches) {
                runCarousel("service-gallery__article", 'carousel', 3);
            } else {

            }

        }

        function reinit_cycle() {
            var destroyCarousel = function () {
                carousel.cycle('destroy');
            }

            if (tablet.matches && smartphone.matches) {
                destroyCarousel();
                reinitCycle('service-gallery__article', 'carousel', 1);
            } else if (!smartphone.matches && tablet.matches) {
                destroyCarousel();
                reinitCycle('service-gallery__article', 'carousel', 3);
            } else {
                destroyCarousel();
                removeCarousel();
                carouselWrap.css("display", "block");
            }
        }

        function reinitCycle(itemStyle, fx, itemsVisible) {
            runCarousel(itemStyle, fx, itemsVisible);
        }

        var reinitTimer;

        $(window).resize(function () {
            clearTimeout(reinitTimer);
            reinitTimer = setTimeout(reinit_cycle, 100);
        });

        $(document).ready(function () {
            initCycle();
        });

    }
}
