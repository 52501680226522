function flashInfos(){

    var flashInfoContainer = $('.flash-info');

    flashInfoContainer.hide(); // Hide flash-info by default (already hidden with styles)

    function checkDateInterval(elem) {
         var startDate = parseInt( elem.attr('data-starttime') ) * 1000;
         var endDate = parseInt( elem.attr('data-endtime') ) * 1000;
         var dateNow = new Date().getTime(); // get current date timestamp
         var showObj = false;

         if ( startDate < dateNow && dateNow < endDate ) {
             showObj = true;
         }
         return showObj;
    }

    var cookieHideFlash = $.cookie('hideFlashInfos');
    var expireIn = new Date();
    expireIn.setDate(expireIn.getDate() + 30); //expiration date: now + 30 days

    if ( checkDateInterval(flashInfoContainer) && cookieHideFlash == null) {
        $('.flash-info').show();
    }

    // cache via cookie
    $('.flash-info .flash-info-close').on('click',function(){
        $('.flash-info').hide();
        $.cookie('hideFlashInfos', 'true', {
            expires: expireIn,
            path: '/'
        });
    });

}
